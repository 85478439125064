@if (statusMeta(); as statusMeta) {
  @let lowerCaseStatus = statusMeta.status.toLowerCase();
  <div [class]="'status-indicator status-indicator--' + lowerCaseStatus">
    <img
      class="status-indicator__icon one-pr-1"
      [alt]="statusMeta.altTextTranslationIdentifier | translate"
      [src]="'/assets/icons/' + statusMeta.icon + '.svg'" />
    <span class="status-indicator__text">
      {{ statusMeta.translationIdentifier | translate }}
    </span>
  </div>
}
