import { Status, STATUS_ICON, StatusMeta } from '../ui/status-indicator/status';

export class StatusMetaProvider {
  private static readonly META_BY_STATUS: Map<Status, StatusMeta> = new Map([
    [
      'NEW',
      {
        status: 'NEW',
        translationIdentifier: 'REQUESTS_DETAILS.STATUS_NEW',
        altTextTranslationIdentifier:
          'REQUESTS_DETAILS.STATUS_NEW_ICON_ALT_TEXT',
        icon: STATUS_ICON.NEW,
      },
    ],
    [
      'PROGRESS',
      {
        status: 'PROGRESS',
        translationIdentifier: 'REQUESTS_DETAILS.STATUS_PROGRESS',
        altTextTranslationIdentifier:
          'REQUESTS_DETAILS.STATUS_PROGRESS_ICON_ALT_TEXT',
        icon: STATUS_ICON.PROGRESS,
      },
    ],
    [
      'PENDING',
      {
        status: 'PENDING',
        translationIdentifier: 'REQUESTS_DETAILS.STATUS_PENDING',
        altTextTranslationIdentifier:
          'REQUESTS_DETAILS.STATUS_PENDING_ICON_ALT_TEXT',
        icon: STATUS_ICON.PENDING,
      },
    ],
    [
      'REJECTED',
      {
        status: 'REJECTED',
        translationIdentifier: 'REQUESTS_DETAILS.STATUS_REJECTED',
        altTextTranslationIdentifier:
          'REQUESTS_DETAILS.STATUS_REJECTED_ICON_ALT_TEXT',
        icon: STATUS_ICON.REJECTED,
      },
    ],
    [
      'DONE',
      {
        status: 'DONE',
        translationIdentifier: 'REQUESTS_DETAILS.STATUS_DONE',
        altTextTranslationIdentifier:
          'REQUESTS_DETAILS.STATUS_DONE_ICON_ALT_TEXT',
        icon: STATUS_ICON.DONE,
      },
    ],
  ]);

  public static provide(status: Status): StatusMeta {
    const meta = StatusMetaProvider.META_BY_STATUS.get(status);
    if (meta) {
      return meta;
    }
    throw new Error(`Unsupported status: ${status}`);
  }

  public static provideAll(): StatusMeta[] {
    return Array.from(StatusMetaProvider.META_BY_STATUS.values());
  }
}
