import { StatusEntryDto } from 'src/api/dso-portal/generated/models/status-entry-dto';

export type Status = StatusEntryDto['status'];

export enum STATUS_ICON {
  NEW = 'plus',
  PROGRESS = 'time',
  PENDING = 'history',
  REJECTED = 'check_minus',
  DONE = 'check_box',
}

export interface StatusMeta {
  status: Status;
  translationIdentifier: string;
  altTextTranslationIdentifier: string;
  icon: STATUS_ICON;
}
