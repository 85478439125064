<button
  class="icon-button"
  [ngClass]="{ 'icon-button--disabled': disabled }"
  [disabled]="disabled"
  (click)="emitClickEvent()">
  <a class="icon-button__link" [routerLink]="route ? [route] : null">
    <span class="icon-button__text">
      <ng-content />
    </span>
    <mat-icon
      [ngClass]="{
        'color-disabled': disabled,
        'icon-button--scale-045': icon === 'arrow_right',
        'icon-button--scale-065': icon !== 'arrow_right',
      }"
      [attr.aria-label]="altText"
      [svgIcon]="icon" />
  </a>
</button>
