import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

export type ButtonIcons = 'arrow_right' | 'download' | 'save' | 'close';
@Component({
  selector: 'dso-icon-button',
  standalone: true,
  imports: [RouterModule, MatIconModule, NgClass],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss',
})
export class IconButtonComponent {
  @Input() public altText: string = 'Button';
  @Input() public route?: string;
  @Input() public icon: ButtonIcons = 'arrow_right';
  @Input() public disabled: boolean = false;

  @Output() private clicked = new EventEmitter<void>();

  public emitClickEvent(): void {
    this.clicked.emit();
  }
}
