import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private readonly authService: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public isAuthenticated$(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  public loginWithRedirect(): void {
    this.authService.loginWithRedirect();
  }

  public logout(): void {
    this.authService.logout({
      logoutParams: { returnTo: this.document.location.origin },
    });
  }
}
