import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject, take } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class IconInitializerService {
  private _configured$: ReplaySubject<void> = new ReplaySubject(1);
  private loadedIcons$: Observable<SVGElement>[] = [];
  private readonly customIcons = [
    'save',
    'arrow_right',
    'download',
    'close',
    'medium_sad_face_emoji',
    'sad_face_emoji',
    'ELECTRICITY',
    'GAS',
    'HEAT',
    'WATER',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.load(this.customIcons);
  }

  public get configured$(): Observable<void> {
    return this._configured$.asObservable();
  }

  private load(customIcons: string[]): void {
    if (customIcons.length === 0) {
      this._configured$.next();
      this._configured$.complete();
    } else {
      customIcons.forEach((icon: string) => {
        const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${icon}.svg`
        );
        this.addIcon(icon, safeUrl);
        this.addIconForPreloading(safeUrl);
      });
      forkJoin(this.loadedIcons$)
        .pipe(take(1))
        .subscribe(() => {
          this._configured$.next();
          this._configured$.complete();
        });
    }
  }

  private addIcon(icon: string, url: SafeUrl): void {
    this.matIconRegistry.addSvgIcon(icon, url);
  }

  private addIconForPreloading(url: SafeUrl): void {
    const svgIconFromUrl = this.matIconRegistry.getSvgIconFromUrl(url);
    this.loadedIcons$.push(svgIconFromUrl);
  }
}
