import { TranslateModule } from '@ngx-translate/core';
import { Component, computed, input, InputSignal } from '@angular/core';
import { Status } from './status';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { StatusMetaProvider } from '@app/shared/util/status-meta-provider';

@Component({
  selector: 'dso-status-indicator',
  imports: [TranslateModule, IconButtonComponent],
  standalone: true,
  templateUrl: './status-indicator.component.html',
  styleUrl: './status-indicator.component.scss',
})
export class StatusIndicatorComponent {
  public readonly status: InputSignal<Status> = input.required<Status>();
  public readonly statusMeta = computed(() =>
    StatusMetaProvider.provide(this.status())
  );
}
