import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private trackingSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private environmentService: EnvironmentService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public startTracking(): void {
    this.configureTracking();
    this.trackingSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event =>
        this.configureTracking((event as NavigationEnd).urlAfterRedirects)
      );
  }

  private configureTracking(pathName: string = window.location.pathname): void {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const uxa = (window as any)._uxa || [];
    (window as any)._uxa = uxa;
    const csConf = typeof (window as any).CS_CONF;
    /* eslint-enable @typescript-eslint/no-explicit-any */

    if (csConf === 'undefined') {
      const csEvent = [
        'setPath',
        window.location.pathname + window.location.hash.replace('#', '?__'),
      ];
      uxa.push(csEvent);
      const mt = this.document.createElement('script');
      mt.type = 'text/javascript';
      mt.async = true;
      mt.id = 'contentsquare-script';
      mt.src = `//t.contentsquare.net/uxa/${this.environmentService.analyticsTagId}.js`;
      this.document.getElementsByTagName('head')[0].appendChild(mt);
    } else {
      const csEvent = [
        'trackPageview',
        pathName + window.location.hash.replace('#', '?__'),
      ];
      uxa.push(csEvent);
    }
    uxa.push(['setCustomVariable', 1, 'app', 'dso-portal']);
  }

  public stopTracking(): void {
    this.document.getElementById('contentsquare-script')?.remove();
    this.trackingSubscription?.unsubscribe();
  }
}
